<template>
  <div>
    <DefaultLayout>
      <PageLoader v-if="loading" />
      <div v-else>
        <component
          v-for="(section, index) in sections"
          :key="index"
          :is="section.component"
          :id="section.component"
          :data="section.data"
          :loading="contactLoading"
          @handleContactUs="handleContactUs"
          @scrollToJoinNow="scrollToSection"
          :resetForm="resetForm"
        ></component>
      </div>
    </DefaultLayout>
    <ToastConfirmationModal
      name="success-modal"
      :title="$t('CONTACT_US.MESSAGE_SENT_SUCCESSFULLY')"
    />
    <WaitListModal
      :data="selectedFormData"
      :loading="contactLoading"
      @handleSubmitForm="handleSubmitForm"
    />
  </div>
</template>

<script>
import { Questions } from "@/constants/Questions"
import HeroSection from "@/components/modules/homepage/HeroSection"
import BuiltOnSection from "@/components/modules/homepage/BuiltOnSection"
import PowerSection from "@/components/modules/homepage/PowerSection"
import HowItWorksSection from "@/components/modules/homepage/HowItWorksSection"
import OneAppSection from "@/components/modules/homepage/OneAppSection"
import QandASection from "@/components/modules/homepage/QandASection"
import OurPartnersSection from "@/components/modules/homepage/OurPartnersSection"
import JoinTheWaitListSection from "@/components/modules/homepage/JoinTheWaitListSection"
import SharkSection from "@/components/modules/homepage/SharkSection"
import ToastConfirmationModal from "@/components/Shared/ToastConfirmationModal"
import { postContactUsRequest } from "@/api/contactUs"
import WaitListModal from "@/components/modules/homepage/WaitListModal"
export default {
  name: "HomeComponent",
  components: {
    HeroSection,
    BuiltOnSection,
    PowerSection,
    HowItWorksSection,
    OneAppSection,
    QandASection,
    OurPartnersSection,
    JoinTheWaitListSection,
    SharkSection,
    ToastConfirmationModal,
    WaitListModal
  },
  data() {
    return {
      loading: true,
      contactLoading: false,
      resetForm: false,
      selectedFormData: null,
      sections: [
        {
          id: 1,
          component: "HeroSection",
          data: {
            title: this.$t("HOMEPAGE.HERO_SECTION.TITLE"),
            text: this.$t("HOMEPAGE.HERO_SECTION.TEXT"),
            label: this.$t("HOMEPAGE.HERO_SECTION.LABEL"),
            btnText: this.$t("HOMEPAGE.HERO_SECTION.BTN_TEXT"),
            img: {
              url: "homepage_hero_section.png",
              alt: "alt"
            }
          }
        },
        {
          id: 2,
          component: "BuiltOnSection",
          data: {
            title: this.$t("HOMEPAGE.BUILT_ON_SECTION.TITLE"),
            text: this.$t("HOMEPAGE.BUILT_ON_SECTION.TEXT"),
            cards: [
              {
                title: this.$t("HOMEPAGE.BUILT_ON_SECTION.CARD_1_TITLE"),
                text: this.$t("HOMEPAGE.BUILT_ON_SECTION.CARD_1_TEXT"),
                img: {
                  url: "homepage_built_on_section_card1.svg",
                  alt: "alt"
                }
              },
              {
                title: this.$t("HOMEPAGE.BUILT_ON_SECTION.CARD_2_TITLE"),
                text: this.$t("HOMEPAGE.BUILT_ON_SECTION.CARD_2_TEXT"),
                img: {
                  url: "homepage_built_on_section_card2.svg",
                  alt: "alt"
                }
              },
              {
                title: this.$t("HOMEPAGE.BUILT_ON_SECTION.CARD_3_TITLE"),
                text: this.$t("HOMEPAGE.BUILT_ON_SECTION.CARD_3_TEXT"),
                img: {
                  url: "homepage_built_on_section_card3.svg",
                  alt: "alt"
                }
              }
            ]
          }
        },
        {
          id: 3,
          component: "PowerSection",
          data: {
            title: this.$t("HOMEPAGE.POWER_SECTION.TITLE"),
            text: this.$t("HOMEPAGE.POWER_SECTION.TEXT"),
            img: {
              url: "homepage_power_section.png",
              alt: ""
            },
            cards: [
              {
                text: this.$t("HOMEPAGE.POWER_SECTION.CARD_1_TEXT"),
                img: {
                  url: "homepage_power_section_card1.svg",
                  alt: "alt"
                }
              },
              {
                text: this.$t("HOMEPAGE.POWER_SECTION.CARD_2_TEXT"),
                img: {
                  url: "homepage_power_section_card2.svg",
                  alt: "alt"
                }
              },
              {
                text: this.$t("HOMEPAGE.POWER_SECTION.CARD_3_TEXT"),
                img: {
                  url: "homepage_power_section_card3.svg",
                  alt: "alt"
                }
              }
            ]
          }
        },
        {
          id: 9,
          component: "SharkSection",
          data: {
            img: {
              url: "homepage_shark_section_shark.png",
              alt: ""
            },
            upperCard: {
              img: {
                url: "homepage_shark_section_card1.svg",
                alt: ""
              },
              title: this.$t("HOMEPAGE.SHARK_SECTION.CARD_1_TITLE"),
              text: this.$t("HOMEPAGE.SHARK_SECTION.CARD_1_TEXT")
            },
            lowerCard: {
              img: {
                url: "homepage_shark_section_card2.svg",
                alt: ""
              },
              title: this.$t("HOMEPAGE.SHARK_SECTION.CARD_2_TITLE"),
              text: this.$t("HOMEPAGE.SHARK_SECTION.CARD_2_TEXT")
            }
          }
        },
        {
          id: 4,
          component: "HowItWorksSection",
          data: {
            title: this.$t("HOMEPAGE.HOW_IT_WORKS_SECTION.TITLE"),
            text: this.$t("HOMEPAGE.HOW_IT_WORKS_SECTION.TEXT"),
            btnText: this.$t("HOMEPAGE.HOW_IT_WORKS_SECTION.BTN_TEXT"),
            img: {
              url: "homepage_how_it_works_section_btn.svg",
              alt: ""
            },
            steps: [
              {
                number: "01",
                tooltip: this.$t("HOMEPAGE.HOW_IT_WORKS_SECTION.STEP_1_TOOLTIP")
              },
              {
                number: "02",
                tooltip: this.$t("HOMEPAGE.HOW_IT_WORKS_SECTION.STEP_2_TOOLTIP")
              },
              {
                number: "03",
                tooltip: this.$t("HOMEPAGE.HOW_IT_WORKS_SECTION.STEP_3_TOOLTIP")
              }
            ]
          }
        },
        {
          id: 5,
          component: "OneAppSection",
          data: {
            title: this.$t("HOMEPAGE.ONE_APP_SECTION.TITLE"),
            text: this.$t("HOMEPAGE.ONE_APP_SECTION.TEXT"),
            features: [
              this.$t("HOMEPAGE.ONE_APP_SECTION.FEATURE1"),
              this.$t("HOMEPAGE.ONE_APP_SECTION.FEATURE2")
            ],
            img: {
              url: "homepage_one_app_section.png",
              alt: ""
            }
          }
        },
        {
          id: 8,
          component: "JoinTheWaitListSection",
          data: {
            title: this.$t("HOMEPAGE.JOIN_THE_WAITLIST_SECTION.TITLE"),
            text: this.$t("HOMEPAGE.JOIN_THE_WAITLIST_SECTION.TEXT")
          }
        },
        {
          id: 6,
          component: "QandASection",
          data: {
            title: this.$t("HOMEPAGE.Q_AND_A_SECTION.TITLE"),
            // text: this.$t("HOMEPAGE.Q_AND_A_SECTION.TEXT"),
            questions: Questions
          }
        },
        {
          id: 7,
          component: "OurPartnersSection",
          data: {
            title: this.$t("HOMEPAGE.OUR_PARTNERS_SECTION.TITLE"),
            images: [
              {
                url: "cyper-me-logo.svg",
                alt: ""
              },
              {
                url: "misk-accelerator-logo.svg",
                alt: ""
              }
            ]
          }
        }
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 1500)
  },
  watch: {
    loading() {
      if (!this.loading && this.$route.query.section) {
        this.$nextTick(() => {
          this.scrollToSection()
        })
      }
    }
  },
  methods: {
    handleContactUs(data) {
      this.selectedFormData = data
      this.$bvModal.show("wait-list-modal")
    },
    handleSubmitForm(data) {
      const params = {
        mobile: this.selectedFormData?.phone,
        email: this.selectedFormData?.email,
        join_as: this.selectedFormData?.joinAs,
        type: "join_us",
        extra_questions: data.map((item) => {
          return {
            question: item.question,
            answer: item.answer,
            sub_answer_text: item.subAnswerText
          }
        })
      }
      this.contactLoading = true
      this.resetForm = false
      this.ApiService(postContactUsRequest(params))
        .then(() => {
          this.$bvModal.hide("wait-list-modal")
          this.$bvModal.show("success-modal")
          this.resetForm = true
        })
        .finally(() => {
          this.contactLoading = false
        })
    },
    scrollToSection() {
      const element = document.getElementById("JoinTheWaitListSection")
      element.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>
