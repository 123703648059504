<template>
  <b-modal id="wait-list-modal" size="lg" hide-footer @hide="closeModal">
    <template v-slot:modal-header="{ close }">
      <b-icon class="close-modal-icon" icon="x" width="30" height="30" @click="close" />
    </template>
    <div class="modal-body" :class="$i18n.locale == 'en' ? '' : 'rtl'">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)" v-if="data">
          <!-- leader questions -->
          <div v-if="data.joinAs === 'leader'">
            <div v-for="(item, index) in leaderQuestions" :key="index" class="question">
              <h5>
                {{ `${index + 1}- ${item.question}` }}
              </h5>
              <div class="mb-2">
                <RadioButton
                  v-model="item.answer"
                  :options="options"
                  value-field="item"
                  text-field="name"
                  rules="required"
                />
              </div>

              <TextField
                v-if="item.answer && item.haveSubAnswerText"
                v-model="item.subAnswerText"
                rules="required"
                :name="item.subQuestion"
                :placeholder="item.subQuestion"
                class="w-100"
              />
            </div>
          </div>
          <!-- follower questions -->

          <div v-else>
            <div v-for="(item, index) in followerQuestions" :key="index" class="question">
              <h5>
                {{ `${index + 1}- ${item.question}` }}
              </h5>
              <div class="mb-2">
                <RadioButton
                  v-model="item.answer"
                  :options="options"
                  value-field="item"
                  text-field="name"
                  rules="required"
                />
              </div>

              <TextField
                v-if="item.answer && item.haveSubAnswerText"
                v-model="item.subAnswerText"
                rules="required"
                :name="item.subQuestion"
                :placeholder="item.subQuestion"
                class="w-100"
              />
            </div>
          </div>

          <div class="text-right mt-4">
            <Button type="submit" :loading="loading">
              {{ $t("GLOBAL.SEND") }}
            </Button>
          </div>
        </b-form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      options: [
        { item: true, name: this.$t("GLOBAL.YES") },
        { item: false, name: this.$t("GLOBAL.NO") }
      ],
      leaderQuestions: [
        {
          question: this.$t("QUESTIONS.ANY_ACTIVE"),
          subQuestion: this.$t("QUESTIONS.APPROXIMATE"),
          answer: false,
          haveSubAnswerText: true,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.HAVE_LARGE_ONLINE"),
          subQuestion: this.$t("QUESTIONS.ENTER_NUMBER"),
          answer: false,
          haveSubAnswerText: true,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.BINANCE_CRYPTO"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.HAVE_EXPERIENCE"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.HAVE_INTENTION"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        }
      ],
      followerQuestions: [
        {
          question: this.$t("QUESTIONS.BINANCE_EXCHANGE"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.INTEND_UTILIZE"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.WILLING_ONLINE"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        }
      ]
    }
  },
  methods: {
    onSubmit() {
      const data = this.data?.joinAs === "leader" ? this.leaderQuestions : this.followerQuestions
      this.$emit("handleSubmitForm", data)
    },
    handleRestForm() {
      this.leaderQuestions = [
        {
          question: this.$t("QUESTIONS.ANY_ACTIVE"),
          subQuestion: this.$t("QUESTIONS.APPROXIMATE"),
          answer: false,
          haveSubAnswerText: true,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.HAVE_LARGE_ONLINE"),
          subQuestion: this.$t("QUESTIONS.ENTER_NUMBER"),
          answer: false,
          haveSubAnswerText: true,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.BINANCE_CRYPTO"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.HAVE_EXPERIENCE"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.HAVE_INTENTION"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        }
      ]
      this.followerQuestions = [
        {
          question: this.$t("QUESTIONS.BINANCE_EXCHANGE"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.INTEND_UTILIZE"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        },
        {
          question: this.$t("QUESTIONS.WILLING_ONLINE"),
          subQuestion: "",
          answer: false,
          haveSubAnswerText: false,
          subAnswerText: ""
        }
      ]
    },
    closeModal() {
      this.handleRestForm()
      this.$emit("closeModal")
      this.$refs.observer.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>
