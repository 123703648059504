<template>
  <b-container ref="myDiv" fluid class="join-the-waitlist">
    <b-row class="w-75 m-auto">
      <b-col class="mb-5 pb-2">
        <h3 class="fs-48 fw-700 text-center mb-4">
          {{ data.title }}
        </h3>
        <p class="fs-20 text-center w-75 m-auto">
          {{ data.text }}
        </p>
      </b-col>
    </b-row>
    <b-container fluid>
      <b-row class="justify-content-center">
        <b-col cols="12" xl="4">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <div class="mb-3">
                <label class="fs-12 fw-400">{{
                  $t("HOMEPAGE.JOIN_THE_WAITLIST_SECTION.INPUT_1_PLACEHOLDER")
                }}</label>
                <TextField
                  v-model="form.email"
                  rules="required|email"
                  :name="$t('HOMEPAGE.JOIN_THE_WAITLIST_SECTION.INPUT_1_PLACEHOLDER')"
                  :placeholder="$t('HOMEPAGE.JOIN_THE_WAITLIST_SECTION.INPUT_1_PLACEHOLDER')"
                  class="w-100"
                />
              </div>
              <div class="mb-3">
                <label class="fs-12 fw-400">{{
                  $t("HOMEPAGE.JOIN_THE_WAITLIST_SECTION.INPUT_2_PLACEHOLDER")
                }}</label>
                <PhoneField
                  v-model="form.phone"
                  rules="required"
                  :name="$t('HOMEPAGE.JOIN_THE_WAITLIST_SECTION.INPUT_2_PLACEHOLDER')"
                />
              </div>
              <div class="d-flex align-items-center mb-3">
                <label class="checkbox-label fs-16 fw-400 mb-0">{{
                  $t("HOMEPAGE.JOIN_THE_WAITLIST_SECTION.UTILIZE")
                }}</label>
                <RadioButton
                  v-model="form.joinAs"
                  :options="feesOptions"
                  :name="$t('HOMEPAGE.JOIN_THE_WAITLIST_SECTION.INPUT_3_PLACEHOLDER')"
                  value-field="item"
                  text-field="name"
                  rules="required"
                />
              </div>
              <div class="btn-container">
                <Button custom-class="w-100" type="submit" class="w-100 mt-4" :loading="loading">
                  {{ $t("HOMEPAGE.JOIN_THE_WAITLIST_SECTION.BTN_TEXT") }}
                </Button>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "JoinTheWaitListSection",
  props: {
    data: {
      type: Object,
      default: () => ({
        title: "",
        text: "",
        btnText: "",
        inputs: [{}]
      })
    },
    loading: {
      type: Boolean,
      default: false
    },
    resetForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dropdownOptions: {
        showDialCodeInSelection: true,
        showFlags: true
      },
      form: {
        phone: "",
        joinAs: "leader",
        email: ""
      },
      feesOptions: [
        { item: "leader", name: this.$t("HOMEPAGE.JOIN_THE_WAITLIST_SECTION.LEADER") },
        { item: "follower", name: this.$t("HOMEPAGE.JOIN_THE_WAITLIST_SECTION.FOLLOWER") }
      ]
    }
  },
  watch: {
    resetForm: {
      immediate: true,
      handler(val) {
        if (val) this.handleResetForm()
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit("handleContactUs", this.form)
    },
    handleResetForm() {
      this.$refs.observer.reset()
      this.form = {
        phone: "",
        joinAs: "leader",
        email: ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
